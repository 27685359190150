import {ElMessage} from "element-plus";

// 邮件效验正则
export function email_check(email){
    const rule = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return rule.test(email);
}

// 普通消息提示
export function message_hint(message){
    ElMessage({
        showClose: true,
        message: message
    });
}

// 成功提示
export function success_hint(message){
    ElMessage({
        showClose: true,
        message: message,
        type: "success"
    });
}

// 警告信息
export function warning_hint(message){
    ElMessage({
        showClose: true,
        message: message,
        type: "warning"
    });
}

// 错误消息
export function error_hint(message){
    ElMessage({
        showClose: true,
        message: message,
        type: "error"
    });
}

// 跳转页面
export function jump_page(path, query=null, new_page=false){
    if(new_page){
        let router_url = null;
        if(query !== null){
            router_url = this.$router.resolve({
                path: path,
                query: query
            });
        }else {
            this.$router.resolve({
                path: path
            });
        }
        window.open(router_url.href, "_blank");
    }else {
        if(query !== null){
            this.$router.push({
                path: path,
                query: query
            })
        }
        else {
            this.$router.push({
                path: path
            });
        }
    }
}