<template>
  <el-header>
    <!--顶部-->
    <div class="top">
      <div class="logo">
        <img :src="logo_src" alt="" @click="jump_page('/')">
      </div>
      <!--操作-->
      <div class="operate" v-if="!is_login">
        <el-button color="#cc2a33" type="primary" @click="jump_page('/login')">登录</el-button>
        <el-button color="#2e2f31" type="primary" @click="jump_page('/register')">注册</el-button>
      </div>
      <div class="operate" v-else>
        <span>欢迎你, 帅逼 OR 美女</span>
        <el-button color="#409EFF" type="primary" @click="jump_page('/info')">个人中心</el-button>
        <el-button color="#409EFF" type="primary" @click="jump_page('/my_data')">我的数据</el-button>
        <el-button color="#409EFF" type="primary" @click="jump_page('/publish_data')">发布数据</el-button>
        <el-button color="#F56C6C" type="primary" @click="logout">注销</el-button>
      </div>
    </div>
    <!--导航-->
    <el-affix>
      <div class="navigation">
        <div class="content">
          <!--菜单-->
          <ul class="menu">
            <li @click="jump_page('/sportsman')">
              <img :src="menu_icons.players" alt="">
              <span>球员</span>
            </li>
            <li @click="jump_page('/team')">
              <img :src="menu_icons.team" alt="">
              <span>球队</span>
            </li>
            <li @click="jump_page('/optimal')">
              <img :src="menu_icons.best" alt="">
              <span>最佳</span>
            </li>
            <li @click="jump_page('/data')">
              <img :src="menu_icons.data" alt="">
              <span>数据</span>
            </li>
            <li @click="jump_page('/skin')">
              <img :src="menu_icons.skin" alt="">
              <span>皮肤</span>
            </li>
            <li @click="jump_page('/tactics')">
              <img :src="menu_icons.tactics" alt="">
              <span>战术</span>
            </li>
            <li @click="jump_page('/video')">
              <img :src="menu_icons.video" alt="">
              <span>视频</span>
            </li>
            <li @click="jump_page('/article')">
              <img :src="menu_icons.article" alt="">
              <span>文章</span>
            </li>
            <li @click="message_hint('正在努力开发中!')">
              <img :src="menu_icons.thank" alt="">
              <span>感谢</span>
            </li>
          </ul>
          <!--搜索框-->
          <div class="search">
            <el-input placeholder="请输入搜索内容" v-model="search_text" clearable style="width: 230px" />
            <el-button color="#35b260" type="primary" @click="search_data">搜索</el-button>
          </div>
        </div>
      </div>
    </el-affix>
    <!--底部-->
    <div class="bottom">
      <div class="message">
        <span>最好数据网——做最好的(FM足球经理)球员数据!</span>
      </div>
    </div>
  </el-header>
</template>

<script>
import {jump_page} from "../tool/tools";
import {message_hint} from "../tool/tools";

export default {
  name: "Header",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  },
  mounted() {
    let token = sessionStorage.getItem("Token")
    if(token !== null && token !== ""){
      this.is_login = true;
    }
  },
  data(){
    return {
      is_login: false,
      logo_src: require("@/static/header/logo.png"),
      menu_icons: {
        players: require("@/static/header/players.png"),
        team: require("@/static/header/team.png"),
        best: require("@/static/header/best.png"),
        data: require("@/static/header/data.png"),
        skin: require("@/static/header/skin.png"),
        tactics: require("@/static/header/tactics.png"),
        video: require("@/static/header/video.png"),
        thank: require("@/static/header/thank.png"),
        article: require("@/static/header/article.png")
      },
      search_text: ""
    }
  },
  methods: {
    jump_page,
    message_hint,
    // 搜索数据
    search_data(){
      // 注册事件
      this.$emit("search", this.search_text);
    },
    // 注销登录
    logout(){
      sessionStorage.removeItem("Token");
      this.is_login = false;
      this.jump_page("/");
    }
  }
}

</script>

<style scoped>

.el-header{
  height: 155px;
  padding: 0;
}
/* 顶部 */
.top {
  max-width: 1050px;
  min-width: 1050px;
  height: 55px;
  margin: 0 auto;
}
.top > .logo {
  margin-top: 10px;
  float: left;
}
.top > .logo > img{
  width: 180px;
  height: 32px;
  cursor: pointer;
}
.top > .operate{
   margin-top: 10px;
   float: right;
 }

.top > .operate > span{
  height: 32px;
  margin-right: 20px;
  font-size: 14px;
}


.navigation {
  min-width: 1050px;
  height: 76px;
  background: #323742;
}

.navigation > .content{
  width: 1050px;
  height: 76px;
  margin: 0 auto;
}

.navigation > .content > .menu{
  list-style: none;
  float: left;
}
.navigation > .content > .menu > li{
  width: 68px;
  height: 76px;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation > .content > .menu > li:hover{
  background: #cc2a33;
}

.navigation > .content > .menu > li > img{
  width: 35px;
  height: 35px;
  margin-bottom: 4px;
}

.navigation > .content > .menu > li > span{
  font-size: 12px;
  color: white;
}
.navigation > .content > .search{
  width: 300px;
  height: 76px;
  float: right;
}

.navigation > .content > .search > .el-input{
  margin-top: 20px;
  float: left;
}

.navigation > .content > .search > .el-button{
  margin-top: 20px;
  margin-left: 5px;
  float: right;
}

.bottom{
  min-width: 1050px;
  height: 24px;
  background: #35b260;
}
.bottom > .message{
  width: 1050px;
  height: 24px;
  line-height: 24px;
  margin: auto;
  font-size: 12px;
  color: white;
  text-align: center;
}


</style>