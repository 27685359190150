<template>
    <el-footer>
      <div class="content">
        <div class="left">
          <p style="color: #a31f2e">FM DATA 是权威的足球经理传奇球员数据网站，于2010年上线。</p>
          <p style="color: #35b260">版权所有 © 2010-2021 www.fmdata.top，您不得照搬和转发作品。</p>
          <p style="color: #959595">足球经理、Sports Interactive 徽标和生成的游戏内图像均属于 © Sports Interactive。</p>
        </div>
        <div class="right">
          <p style="color: #a31f2e">技术支持: Hoime</p>
          <p style="color: #35b260">团队成员</p>
          <p style="color: #959595">baggio0453、azuredragon、figo122426、Azeroth、t a'ゞ追风男孩、暴走羊驼、圈</p>
        </div>
      </div>
    </el-footer>
</template>

<script>
export default {
  name: "Footer",
  beforeCreate() {
    // 设置CSS样式
    document.querySelector("body").setAttribute("style", "margin: 0;padding: 0;")
  }
}
</script>

<style scoped>

.el-footer{
  padding: 0;
  height: 150px;
  background: #323742;
}
.content{
  font-size: 12px;
  max-width: 1050px;
  min-width: 1050px;
  height: 150px;
  background: #323742;
  margin: 0 auto;
}
.content{
  font-size: 12px;
  max-width: 1050px;
  min-width: 1050px;
  height: 150px;
  background: #323742;
}

.content > .left{
  width: 464px;
  height: 90px;
  margin-top: 25px;
  float: left;
}
.content > .left > p{
  height: 30px;
  line-height: 30px;
}

.content > .right {
  margin-top: 25px;
  text-align: right;
  float: right;
}
.content > .right > p{
  height: 30px;
  line-height: 30px;
}
</style>